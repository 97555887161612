import { Navigate, useRoutes } from 'react-router-dom';

// auth
import AuthGuard from '../auth/AuthGuard';
import GuestGuard from '../auth/GuestGuard';
// layouts
import CompactLayout from '../layouts/compact';
import DashboardLayout from '../layouts/dashboard';
import RoleBasedGuard from '../auth/RoleBasedGuard';
// config
import { PATH_AFTER_LOGIN } from '../config-global';
//
import {
  // AUTH
  ResetPasswordPage,
  LoginPage,
  RegisterPage,
  VerifyCodePage,
  VerifyCodePageForgot,
  TwoFactorPage,
  // MANAGEMENT
  UserListPage,
  UserCreatePage,
  UserEditPage,
  ClientPage,
  InternalPhones,
  // HOME
  Home,
  LogsPage,
  // NAD V3
  NadHomePage,
  NadSingleClientPage,
  NadEmailSettings,
  NadWhitelist,
  AdminPanelPage,
  NadBackupPage,
  // MAP
  MapHomePage,
  MapJobsPage,
  MapEditNewClient,
  MapSchedulingPage,
  MapStatisticsPage,
  // INFORMATICI,
  FerieCalendarPage,
  // CALENDARIO
  CalendarPage,
  // BACHECA
  BachecaHomePage,
  // SCARICAMENTI,
  ScaricamentiPage,
  ClientiPage,
  FacetsPage,
  SchedulingPage,
  // MANAGEMTN
  PrenotazioniPage,
  TicketPage,
  NewTicketPage,
  SingleTicketPage,
  NewChangelogPage,
  SingleChangelogPage,
  UserAccountPage,
  AnalystSupportPage,
  ManagementPage,
  // DNS
  SummaryPage,
  DiscoverPage,
  SummaryDetailsPage,
  // RISORSE UMANE
  RisorseUmaneHomePage,
  // RAWDATA
  RawDataPage,
  RawDataClientPage,
  // WIKI
  WikiPage,
  WikiCategoryPage,
  WikiArticlePage,
  WikiAddNewArticlePage,
  // CRAWLING
  CrawlingHomePage,
  // ERRORS
  Page404,
  // MARKETPLACE
  MarketplaceHomePage,
  MarketplaceEditClientPage,
  MarketplaceSingleTablePage,
  MarketplaceProjectsPage,
  MarketplaceSingleTableSettingsPage,
  // IDENTITA
  IdentitàDigitalePage,
  IdentitàDigitaleClientPage,
  // STREAM
  StreamPage,
  // SOCIALS
  SocialsPage,
  SocialsHome,
  SocialsJobs,
  // UDRP
  UdrpPage,
  // GALLERY
  GalleryPage,
  JobView,
  // SIMILARITY
  ClientsSimilarityPage,
  JobsListSimilarityPage,
  MatchJob,
  // DIFFIDE
  DiffideHome,
  DamageCompensationPage,
  ClientSettingsPage,
  // TRACCIAMENTO
  TracciamentoHome,
  TracciamentoSettings,
  // HELPDESK
  HelpDeskPage,
  // TIENAMMENT
  TienammentHomePage,
  // CROM
  CromHome,
  CromAdd,
  // CONVEY LENS
  ConveyLensClients,
  LensDetailsPage,
} from './elements';

// ----------------------------------------------------------------------

export default function Router() {
  return useRoutes([
    {
      path: '/',
      children: [
        { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
        {
          path: 'login',
          element: (
            <GuestGuard>
              <LoginPage />
            </GuestGuard>
          ),
        },
        {
          path: 'register',
          element: (
            <GuestGuard>
              <RegisterPage />
            </GuestGuard>
          ),
        },
        {
          element: <CompactLayout />,
          children: [
            { path: 'reset-password', element: <ResetPasswordPage /> },
            { path: 'verify', element: <VerifyCodePage /> },
            { path: 'verify-forgot', element: <VerifyCodePageForgot /> },
            { path: 'two-factor', element: <TwoFactorPage /> },
          ],
        },
      ],
    },
    {
      path: '/app',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
        { path: 'home', element: <Home /> },
        { path: 'analystsupport', element: <AnalystSupportPage /> },
        { path: 'stream', element: <StreamPage /> },
        {
          path: 'designs',
          children: [
            { path: 'home', element: <SocialsHome /> },
            { path: ':client_name/:job_id', element: <SocialsPage /> },
            { path: ':client_name/:design_id/jobs', element: <SocialsJobs /> },
          ],
        },
        { path: 'gallery', element: <GalleryPage /> },
        { path: 'gallery', element: <GalleryPage /> },
        { path: 'gallery/:id', element: <JobView /> },
        {
          path: 'logs',
          element: (
            <RoleBasedGuard hasContent roles={['Admin']}>
              <LogsPage />
            </RoleBasedGuard>
          ),
        },
        {
          path: 'identita',
          element: (
            <RoleBasedGuard hasContent roles={['Identita']}>
              <IdentitàDigitalePage />
            </RoleBasedGuard>
          ),
        },
        {
          path: 'identita/:client_name',
          element: (
            <RoleBasedGuard hasContent roles={['Identita']}>
              <IdentitàDigitaleClientPage />
            </RoleBasedGuard>
          ),
        },
        {
          path: 'mngt',

          children: [
            {
              element: (
                <RoleBasedGuard
                  hasContent
                  roles={['Admin', 'UserManager', 'ClientManager', 'PhonesManager']}
                >
                  <ManagementPage />
                </RoleBasedGuard>
              ),
              index: true,
            },
            {
              path: 'users',
              element: (
                <RoleBasedGuard hasContent roles={['Admin', 'UserManager']}>
                  <UserListPage />
                </RoleBasedGuard>
              ),
            },
            {
              path: 'new-user',
              element: (
                <RoleBasedGuard hasContent roles={['Admin', 'UserManager']}>
                  <UserCreatePage />
                </RoleBasedGuard>
              ),
            },
            {
              path: ':name/edit',
              element: (
                <RoleBasedGuard hasContent roles={['Admin', 'UserManager']}>
                  <UserEditPage />
                </RoleBasedGuard>
              ),
            },
            {
              path: 'clients',
              element: (
                <RoleBasedGuard hasContent roles={['Admin', 'ClientManager']}>
                  <ClientPage />
                </RoleBasedGuard>
              ),
            },
            {
              path: 'phones',
              element: (
                <RoleBasedGuard hasContent roles={['Admin', 'PhonesManager']}>
                  <InternalPhones />
                </RoleBasedGuard>
              ),
            },
          ],
        },
        {
          path: 'account/profile',
          element: <UserAccountPage />,
        },
        {
          path: 'hr',
          children: [
            {
              path: 'home',
              element: (
                <RoleBasedGuard hasContent not_roles={['Tirocinante']}>
                  <RisorseUmaneHomePage />
                </RoleBasedGuard>
              ),
            },
          ],
        },
        {
          path: 'help-desk',
          element: <HelpDeskPage />,
        },
        {
          path: 'diffide',
          children: [
            {
              path: 'home',
              element: <DiffideHome />,
            },
            {
              path: 'damagecompensation',
              element: (
                <RoleBasedGuard hasContent roles={['DamageCompensation']}>
                  <DamageCompensationPage />
                </RoleBasedGuard>
              ),
            },
            {
              path: 'clients-settings',
              element: (
                <RoleBasedGuard roles={['DamageCompensation']}>
                  <ClientSettingsPage />
                </RoleBasedGuard>
              ),
            },
          ],
        },
        {
          path: 'match',
          children: [
            {
              path: 'clients',
              element: <ClientsSimilarityPage />,
            },
            {
              path: ':client_name',
              element: <JobsListSimilarityPage />,
            },
            {
              path: ':client_name/:job_id',
              element: <MatchJob />,
            },
          ],
        },
        {
          path: 'udrp',
          children: [
            {
              path: 'home',
              element: (
                <RoleBasedGuard hasContent roles={['Udrp']}>
                  <UdrpPage />
                </RoleBasedGuard>
              ),
            },
          ],
        },
        {
          path: 'crawling',
          children: [{ path: 'home', element: <CrawlingHomePage /> }],
        },
        {
          path: 'nadv3',
          children: [
            {
              path: 'home',
              element: (
                <RoleBasedGuard hasContent roles={['Admin', 'Nad', 'NadAdmin']}>
                  <NadHomePage />
                </RoleBasedGuard>
              ),
            },
            {
              path: ':client_name/table',
              element: (
                <RoleBasedGuard hasContent roles={['Admin', 'Nad', 'NadAdmin']}>
                  <NadSingleClientPage />
                </RoleBasedGuard>
              ),
            },
            {
              path: ':client_name/email-settings',
              element: (
                <RoleBasedGuard hasContent roles={['Admin', 'NadAdmin']}>
                  <NadEmailSettings />
                </RoleBasedGuard>
              ),
            },
            {
              path: ':client_name/whitelist',
              element: (
                <RoleBasedGuard hasContent roles={['Admin', 'NadAdmin', "Nad"]}>
                  <NadWhitelist />
                </RoleBasedGuard>
              ),
            },
            {
              path: 'adminpanel',
              element: (
                <RoleBasedGuard hasContent roles={['Admin', 'NadAdmin']}>
                  <AdminPanelPage />
                </RoleBasedGuard>
              ),
            },
            {
              path: 'backuppanel',
              element: (
                <RoleBasedGuard hasContent roles={['Admin', 'NadAdmin']}>
                  <NadBackupPage />
                </RoleBasedGuard>
              ),
            },
          ],
        },
        {
          path: 'marketplace',
          children: [
            {
              path: 'home',
              element: (
                <RoleBasedGuard hasContent roles={['Admin', 'Marketplace', 'MarketplaceAdmin']}>
                  <MarketplaceHomePage />
                </RoleBasedGuard>
              ),
            },
            {
              path: ':id/edit',
              element: (
                <RoleBasedGuard hasContent roles={['Admin', 'MarketplaceAdmin']}>
                  <MarketplaceEditClientPage />
                </RoleBasedGuard>
              ),
            },
            {
              path: ':id/table',
              element: (
                <RoleBasedGuard hasContent roles={['Admin', 'Marketplace', 'MarketplaceAdmin']}>
                  <MarketplaceProjectsPage />
                </RoleBasedGuard>
              ),
            },
            {
              path: ':id/:table/table',
              element: (
                <RoleBasedGuard hasContent roles={['Admin', 'Marketplace', 'MarketplaceAdmin']}>
                  <MarketplaceSingleTablePage />
                </RoleBasedGuard>
              ),
            },
            {
              path: ':id/:table/settings',
              element: (
                <RoleBasedGuard hasContent roles={['Admin', 'MarketplaceAdmin']}>
                  <MarketplaceSingleTableSettingsPage />
                </RoleBasedGuard>
              ),
            },
          ],
        },
        {
          path: 'map',
          children: [
            {
              path: 'home',
              element: (
                <RoleBasedGuard hasContent roles={['Admin', 'Map']}>
                  <MapHomePage />
                </RoleBasedGuard>
              ),
            },
            {
              path: 'new',
              element: (
                <RoleBasedGuard hasContent roles={['Admin', 'Map']}>
                  <MapEditNewClient />
                </RoleBasedGuard>
              ),
            },
            {
              path: ':client/edit',
              element: (
                <RoleBasedGuard hasContent roles={['Admin', 'Map']}>
                  <MapEditNewClient />
                </RoleBasedGuard>
              ),
            },
            {
              path: 'jobs',
              element: (
                <RoleBasedGuard hasContent roles={['Admin', 'Map']}>
                  <MapJobsPage />
                </RoleBasedGuard>
              ),
            },
            {
              path: ':client/jobs',
              element: (
                <RoleBasedGuard hasContent roles={['Admin', 'Map']}>
                  <MapJobsPage />
                </RoleBasedGuard>
              ),
            },
            {
              path: ':client/scheduling',
              element: (
                <RoleBasedGuard hasContent roles={['Admin', 'Map']}>
                  <MapSchedulingPage />
                </RoleBasedGuard>
              ),
            },
            {
              path: 'scheduling',
              element: (
                <RoleBasedGuard hasContent roles={['Admin', 'Map']}>
                  <MapSchedulingPage />
                </RoleBasedGuard>
              ),
            },
            {
              path: 'stats',
              element: (
                <RoleBasedGuard hasContent roles={['Admin', 'Map']}>
                  <MapStatisticsPage />
                </RoleBasedGuard>
              ),
            },
          ],
        },
        {
          path: 'crom',
          children: [
            { path: 'home', element: <CromHome /> },
            { path: 'add', element: <CromAdd /> },
            {
              path: 'discovery',
              element: <TienammentHomePage />,
            },
          ],
        },
        {
          path: 'scaricamenti',
          children: [
            {
              path: 'home',
              element: <ScaricamentiPage />,
            },
            {
              path: 'clienti',
              element: <ClientiPage />,
            },
            {
              path: 'scheduling',
              element: <SchedulingPage />,
            },

            {
              path: 'facets',
              element: (
                <RoleBasedGuard
                  hasContent
                  roles={['Admin', 'ScaricamentiDeveloper', 'FacetsDeveloper']}
                >
                  <FacetsPage />
                </RoleBasedGuard>
              ),
            },
          ],
        },
        {
          path: 'prenotazioni',
          children: [
            {
              path: 'home',
              element: <PrenotazioniPage />,
            },
          ],
        },
        {
          path: 'rendicontazione',
          children: [
            {
              path: 'home',
              element: (
                <RoleBasedGuard
                  hasContent
                  roles={['Tracking', 'TrackingCluster', 'TrackingResponsabile', 'TrackingAdmin']}
                >
                  <TracciamentoHome />
                </RoleBasedGuard>
              ),
            },
            {
              path: 'settings',
              element: (
                <RoleBasedGuard hasContent roles={['TrackingAdmin']}>
                  <TracciamentoSettings />
                </RoleBasedGuard>
              ),
            },
          ],
        },
        {
          path: 'assenze',
          children: [
            {
              path: 'home',
              element: <FerieCalendarPage />,
            },
          ],
        },
        {
          path: 'bacheca',
          children: [
            {
              path: 'home',
              element: <BachecaHomePage />,
            },
          ],
        },
        {
          path: 'calendario',
          element: (
            <RoleBasedGuard hasContent roles={['Admin', 'Calendario', 'CalendariManager']}>
              <CalendarPage />
            </RoleBasedGuard>
          ),
        },
        {
          path: 'ticket',
          children: [
            {
              path: 'home',
              element: <TicketPage />,
            },
            {
              path: 'new',
              element: <NewTicketPage />,
            },
            {
              path: 'page/:id',
              element: <SingleTicketPage />,
            },
          ],
        },
        {
          path: 'rawdata',
          children: [
            {
              path: 'home',
              element: (
                <RoleBasedGuard hasContent roles={['Admin', 'RawData']}>
                  <RawDataPage />
                </RoleBasedGuard>
              ),
            },
            {
              path: 'client/:name',
              element: (
                <RoleBasedGuard hasContent roles={['Admin', 'RawData']}>
                  <RawDataClientPage />
                </RoleBasedGuard>
              ),
            },
          ],
        },
        {
          path: 'news',
          children: [
            {
              path: 'new',
              element: (
                <RoleBasedGuard hasContent roles={['Admin', 'ChangelogManager']}>
                  <NewChangelogPage />
                </RoleBasedGuard>
              ),
            },
            {
              path: 'page/:id',
              element: <SingleChangelogPage />,
            },
          ],
        },
        {
          path: 'dns',
          children: [
            {
              path: 'discovery',
              element: <DiscoverPage />,
            },
            {
              path: 'summary',
              element: <SummaryPage />,
            },
            {
              path: 'summary/detail/:id',
              element: <SummaryDetailsPage />,
            },
          ],
        },
        {
          path: 'wiki',
          children: [
            {
              path: 'home',
              element: <WikiPage />,
            },
            {
              path: 'category/:id',
              element: <WikiCategoryPage />,
            },
            {
              path: 'article/:id',
              element: <WikiArticlePage />,
            },
            {
              path: 'new',
              element: (
                <RoleBasedGuard hasContent roles={['Admin', 'WikiManager']}>
                  <WikiAddNewArticlePage />
                </RoleBasedGuard>
              ),
            },
          ],
        },
        { path: 'g8', children: [
          { path: 'clients', element: <ConveyLensClients /> },
          { path: ':client_name', element: <LensDetailsPage /> },
        ] },
      ],
    },
    {
      element: <CompactLayout />,
      children: [{ path: '404', element: <Page404 /> }],
    },
    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}
